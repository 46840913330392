<template>
  <v-container>
    <v-row
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Mark:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        {{ value.markName }}
      </v-col>
    </v-row>
    <v-row
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Jurisdiction:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        {{ value.jurisdiction.name }}, {{ value.jurisdiction.countryCode }}
      </v-col>
    </v-row>
    <v-row
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Goods and Services:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        {{ value.goodsAndServices }}
      </v-col>
    </v-row>
    <v-row
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Holders:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        <ul>
          <li
            v-for="(holder, idx) in value.holders"
            :key="idx">
            <contact :value="holder"/>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="value.contacts ? value.contacts.length > 0 : false"
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Contacts:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        <ul>
          <li
            v-for="(contact, idx) in value.contacts"
            :key="idx">
            <contact :value="contact"/>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="value.trademarkClasses ? value.trademarkClasses.length > 0 : false"
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Trademark Classes:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        <ul>
          <li
            v-for="(trademarkClass, idx) in value.trademarkClasses"
            :key="idx">
            {{ trademarkClass.number }}: {{ trademarkClass.name }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="value.notExactMatch? value.notExactMatch.udrpCases ? value.notExactMatch.udrpCases.length > 0 : false : false"
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        UDRP Cases:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        <ul>
          <li
            v-for="(udrpCase, idx) in value.notExactMatch.udrpCases"
            :key="idx">
            {{ udrpCase.caseNumber }}, {{ udrpCase.provider }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="value.notExactMatch? value.notExactMatch.courtDecisions ? value.notExactMatch.courtDecisions.length > 0 : false : false"
      class="pt-4"
      justify="space-around">
      <v-col cols="12" md="3">
        Court Decisions:
      </v-col>
      <v-col
        class="align-content-start"
        cols="12" md="9">
        <ul>
          <li
            v-for="(decision, idx) in value.notExactMatch.courtDecisions"
            :key="idx">
            {{ decision.courtName }}, {{ decision.courtLocation }} : {{ decision.refNumber }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Contact from './Contact'

  export default {
    name: 'Claim',

    components: {
      Contact
    },

    props: {
      value: {
        type: Object,
        required: true
      }
    }
  }
</script>
