<template>
  <v-container>
    <v-row
      v-if="value.entitlement"
      class="pt-2">
      <v-col cols="12" sm="4">
        Entitlement:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.entitlement }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.type"
      class="pt-2">
      <v-col cols="12" sm="4">
        Type:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.type }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.name"
      class="pt-2">
      <v-col cols="12" sm="4">
        Name:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.name }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.organization"
      class="pt-2">
      <v-col cols="12" sm="4">
        Organisation:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.organization }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.streets"
      class="pt-2">
      <v-col cols="12" sm="4">
        Street:
      </v-col>
      <v-col cols="12" sm="8">
        <template v-for="(street, idx) in value.streets">
          {{ street }}
          <template v-if="idx !== value.streets.length-1">
            ,
          </template>
        </template>
      </v-col>
    </v-row>
    <v-row
      v-if="value.city"
      class="pt-2">
      <v-col cols="12" sm="4">
        City:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.city }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.postalCode"
      class="pt-2">
      <v-col cols="12" sm="4">
        Postal Code:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.postalCode }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.stateOrProvince"
      class="pt-2">
      <v-col cols="12" sm="4">
        State:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.stateOrProvince }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.countryCode"
      class="pt-2">
      <v-col cols="12" sm="4">
        Country:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.countryCode }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.email"
      class="pt-2">
      <v-col cols="12" sm="4">
        E-Mail:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.email }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.faxNumber"
      class="pt-2">
      <v-col cols="12" sm="4">
        Fax Number:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.faxNumber.phoneNumber }}, {{ value.faxNumber.extension }}
      </v-col>
    </v-row>
    <v-row
      v-if="value.voiceNumber"
      class="pt-2">
      <v-col cols="12" sm="4">
        Voice Number:
      </v-col>
      <v-col cols="12" sm="8">
        {{ value.voiceNumber.phoneNumber }}, {{ value.voiceNumber.extension }}
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'Contact',

    props: {
      value: {
        type: Object,
        required: true
      }
    }
  }
</script>
