<template>
  <v-app>
    <v-container>
      <v-row
        class="fill-height"
        align="center"
        justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-card>
            <v-card-text>
              <v-card
                id="scroll-target"
                v-scroll="onScroll"
                flat
                class="scroll"
                height="70vh"
                mt-12>
                <v-slide-y-transition mode="out-in">
                  <v-progress-linear
                    v-if="isLoadingNotice"
                    indeterminate
                    color="primary"/>
                  <template v-else-if="error">
                    <v-alert type="error">
                      {{ errorMsg }}.
                    </v-alert>
                  </template>
                  <template v-else-if="acceptError">
                    <v-alert type="error">
                      {{ acceptErrorMsg }}.
                    </v-alert>
                  </template>
                  <template v-else-if="success">
                    <v-alert type="success">
                      The Claims Notice was successfully accepted.
                    </v-alert>
                  </template>
                  <template v-else>
                    <v-row
                      v-scroll:#scroll-target="onScroll"
                      justify="center">
                      <v-col cols="8">
                        <h1 class="text-center">
                          TRADEMARK NOTICE
                        </h1>
                      </v-col>
                      <v-col cols="8">
                        <p>
                          You have received this Trademark Notice because you have applied for a domain name which
                          matches at least one trademark record submitted to the Trademark Clearinghouse.
                        </p>
                      </v-col>
                      <v-col cols="8">
                        <p>
                          <b>
                            You may or may not be entitled to register the domain name depending on your intended use
                            and whether it is the same or significantly overlaps with the trademarks listed below. Your
                            rights to register this domain name may or may not be protected as noncommercial use or
                            “fair use” by the laws of your country.
                          </b>
                        </p>
                      </v-col>
                      <v-col cols="8">
                        <p>
                          Please read the trademark information below carefully, including the trademarks,
                          jurisdictions, and goods and services for which the trademarks are registered. Please be aware
                          that not all jurisdictions review trademark applications closely, so some of the trademark
                          information below may exist in a national or regional registry which does not conduct a
                          thorough or substantive review of trademark rights prior to registration. If you have
                          questions, you may want to consult an attorney or legal expert on trademarks and intellectual
                          property for guidance.
                        </p>
                      </v-col>
                      <v-col cols="8">
                        <p>
                          If you continue with this registration, you represent that, you have received and you
                          understand this notice and to the best of your knowledge, your registration and use of the
                          requested domain name will not infringe on the trademark rights listed below. The following
                          marks are listed in the Trademark Clearinghouse:
                        </p>
                      </v-col>
                      <v-col
                        class="pa-4"
                        cols="8">
                        <ol>
                          <li
                            v-for="claim in notice.claims"
                            :key="claim.markName">
                            <claim :value="claim"/>
                          </li>
                        </ol>
                      </v-col>
                    </v-row>
                  </template>
                </v-slide-y-transition>
              </v-card>
              <v-col v-if="!isLoadingNotice && !error && !success && !acceptError && showSubmit">
                You must scroll through the whole Trademark Notice in order to proceed
              </v-col>
            </v-card-text>
            <v-slide-y-transition mode="out-in">
              <v-card-actions v-if="!isLoadingNotice && !error && !success && !acceptError && showSubmit">
                <v-row>
                  <v-col
                    class="wrap"
                    cols="8">
                    <v-row justify="center">
                      <v-checkbox
                        v-model="accept"
                        :label="`I understand, I have read this notice, and wish to proceed with the registration of ${'domain here'}`"/>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-row justify="end">
                      <v-col
                        v-if="acceptError"
                        class="red--text">
                        {{ acceptErrorMsg }}
                      </v-col>
                      <!-- TODO, FIXME: translate? -->
                      <v-btn
                        text
                        @click.prevent="cancel">
                        Cancel Registration
                      </v-btn>
                      <!-- TODO, FIXME: translate? -->
                      <v-btn
                        :loading="isLoadingSubmit"
                        :disabled="!(accept && scrolled)"
                        color="primary"
                        @click="submit">
                        proceed
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions v-else-if="!showSubmit">
                <v-col
                  class="wrap"
                  cols="12">
                  <v-alert type="error">
                    {{ errorMsg }}.
                  </v-alert>
                </v-col>
              </v-card-actions>
            </v-slide-y-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import {mapActions} from 'vuex'

  import Claim from './components/Claim'

  export default {
    name: 'ClaimsNotice',

    components: {
      Claim
    },

    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },

    data () {
      return {
        isLoadingNotice: true,
        isLoadingSubmit: false,
        error: false,
        errorMsg: '',
        acceptError: false,
        acceptErrorMsg: '',
        accept: false,
        notice: null,
        success: false,
        scrolled: false,
        showSubmit: true
      }
    },

    watch: {
      id () {
        this.isLoadingNotice = true
        this.isLoadingSubmit = false
        this.error = false
        this.errorMsg = ''
        this.acceptError = false
        this.acceptErrorMsg = ''
        this.accept = false
        this.notice = null
        this.success = false
        this.scrolled = false
        this.showSubmit = true
        this.loadClaimsNotice ()
      }
    },

    created () {
      this.loadClaimsNotice ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      onScroll (e) {
        if ((e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight) < 80) {
          this.scrolled = true
        }
      },

      loadClaimsNotice () {
        this.isLoadingNotice = true
        this.fetchData ({
          op: 'claimsnotice/load',
          params: {
            acceptanceId: this.id
          },
          cb: data => {
            if (data.expired || data.notValidYet || data.alreadyAccepted) {
              this.handleError (data)
            } else {
              this.notice = data.data
              this.error = false
            }
          },
          cbError: (data) => {
            if (data.result.find (({code}) => {
              return code === 'error/datamgmt/objdoesnotexist'
            })) {
              this.errorMsg = 'This notice does not exist'
            } else {
              this.errorMsg = `Some error has occured (id: ${this.$route.params.id})`
            }
            this.error = true
          },
          cbFinal: () => {
            this.isLoadingNotice = false
          }
        })
      },

      handleError (data) {
        if (data.expired) {
          this.errorMsg = 'This notice is expired'
          this.error = true
        } else if (data.notValidYet) {
          this.errorMsg = 'This notice is not valid yet'
          this.error = true
        } else if (data.alreadyAccepted) {
          this.notice = data.data
          this.errorMsg = 'This notice was already accepted'
          this.showSubmit = false
        }
      },

      submit () {
        if (this.accept) {
          this.isLoadingSubmit = true
          this.fetchData ({
            op: 'claimsnotice/accept',
            params: {
              acceptanceId: this.$route.params.id
            },
            cb: data => {
              if (data.expired || data.notValidYet || data.alreadyAccepted) {
                this.handleAcceptError (data)
              } else {
                this.success = true
              }
            },
            cbError: (data) => {
              if (data.result.find (({code}) => {
                return code === 'error/datamgmt/objdoesnotexist'
              })) {
                this.errorMsg = 'This notice does not exist'
              } else {
                this.errorMsg = `Some error has occured (id: ${this.$route.params.id})`
              }
              this.error = true
            },
            cbFinal: () => {
              this.isLoadingSubmit = false
            }
          })
        }
      },

      cancel () {
        this.$router.push ({name: 'dashboard'})
      },

      handleAcceptError (data) {
        if (data.expired) {
          this.acceptErrorMsg = 'This notice is expired'
        } else if (data.notValidYet) {
          this.acceptErrorMsg = 'This notice is not valid yet'
        } else if (data.alreadyAccepted) {
          this.acceptErrorMsg = 'This notice was already accepted'
        }

        this.acceptError = true
      }
    }
  }
</script>

<style scoped>
  .scroll {
    overflow-y: auto;
  }
</style>
